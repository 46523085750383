import styled from "styled-components";

import background from "../../assets/sign-up-page.png";

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background-image: url(${background});
  background-size: 90% 90%;
  background-repeat: no-repeat;
  background-position: 10%;
  margin-left: 5vw;
  position: relative;

  @media screen and (max-width: 600px) {
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: 50%;
    -webkit-transform: rotate(90deg);
    height: 100vh;
    width: 100vw;
  }
  .inputs {
    position: absolute;
    top: 47vh;
    display: flex;
    flex-direction: column;
    left: 45vw;
  }
  input {
    margin-top: 3vh;
    height: 3vh;
    width: 23vw;
    outline: none;
    border: none;
    border-radius: 3px;
    padding: 5px 10px;
  }
  .bottom-buttons {
    display: flex;
    justify-content: space-around;
    width: 50vw;
    position: absolute;
    top: 74vh;
    left: 50%;
    transform: translateX(-50%);
    button {
      max-width: 15vw !important;
      background-color: transparent;
      border: none;
      height: 10vh;
      img {
        width: 100%;
        height: 100% !important;
      }
    }
    .google-login {
      max-width: 19vw !important;
    }
  }
  .login {
    background-color: transparent;
    border: none;
    width: 13vw;
    position: absolute;
    top: 50vh;
    left: 64vw;
    img {
      width: 100%;
      height: 100%;
    }
  }
`;
