export const findList = (players, take10) => {
  let tempPlayers = [...players];
  console.log(take10);
  take10.playedBy !== -1 && tempPlayers.splice(take10.playedBy, 1);

  console.log(tempPlayers);

  let playersCardValue = [];
  tempPlayers.map((player, index) => {
    let sum = 0;
    player.cards.map((card) => {
      switch (card[0]) {
        case "K":
          if (card[1] === "H") {
            sum += 20;
          } else sum += 4;
          break;
        case "Q":
          sum += 3;
          break;
        case "J":
          sum += 2;
          break;
        case "A":
          sum += 11;
          break;
        case "1":
          sum += 10;
          break;

        default:
          sum += parseInt(card[0]);
          break;
      }
    });

    console.log(player.name, sum);
    playersCardValue.push({
      player: player.name,
      score: sum,
      cards: player.cards,
    });
  });
  console.log(findMin(playersCardValue), "loooser");
  return findMin(playersCardValue);
};

const findMin = (players) => {
  return players.reduce(function (prev, curr) {
    return prev.score < curr.score ? prev : curr;
  });
};
