import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import signUp from "../../assets/signup.png";
import { Container } from "./sign-up.styles";

import { ToastContainer, toast } from "react-toastify";
import { ENDPOINT } from "../../api/Api";

export default function SignUp({ history }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [wrong, setWrong] = useState(false);
  const [wrongEmail, setWrongEmail] = useState(false);
  let navigate = useNavigate();
  const handleSignUp = (e) => {
    e.preventDefault();
    if (password === confirmPassword) {
      signupHandler();
    } else {
      toast.error("password didn't match", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setWrong(true);
    }
  };
  const signupHandler = async () => {
    // props.history.push("/news-overview");
    axios
      .post(`${ENDPOINT}/api/sign-up`, {
        email: email,
        password: password,
      })

      .then(function (response) {
        sessionStorage.setItem("token", response.data);
        toast.done("Registerd", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        navigate("/");
      })
      .catch(function (error) {
        toast.error("email taken", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setWrongEmail(true);
      })
      .then(function () {
        // always executed
      });
  };
  const onLoginSuccess = (res) => {
    console.log("login success", res);
  };

  const onFailureSucceess = (res) => {
    console.log("login failed", res);
  };

  return (
    <Container>
      <ToastContainer></ToastContainer>
      <div className="inputs">
        <input
          type="email"
          onChange={(e) => setEmail(e.target.value)}
          value={email}
          style={{ border: wrongEmail ? "2px solid red" : "none" }}
        />
        <input
          type="password"
          onChange={(e) => setPassword(e.target.value)}
          value={password}
          style={{ border: wrong ? "2px solid red" : "none" }}
        />
        <input
          type="password"
          onChange={(e) => setConfirmPassword(e.target.value)}
          value={confirmPassword}
          style={{ border: wrong ? "2px solid red" : "none" }}
        />
      </div>

      <div className="bottom-buttons">
        <button onClick={handleSignUp}>
          <img src={signUp} alt="login" className="normal-login" />
        </button>
      </div>
    </Container>
  );
}
