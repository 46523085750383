import Static from "../static/static.page";
import { Container } from "./playing-room.styles";

import React, { useEffect, useState } from "react";
import io from "socket.io-client";
import { ENDPOINT } from "../../api/Api";

const PlayingRoom = (props) => {
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    const connectionOptions = {
      forceNew: true,
      reconnectionAttempts: "Infinity",
      timeout: 10000,
      transports: ["websocket"],
      path: "/mysocket",
    };
    const newSocket = io.connect(ENDPOINT, connectionOptions);

    // const newSocket = io(ENDPOINT);
    setSocket(newSocket);
    // console.log('socket connected')
    return () => newSocket.close();
  }, [setSocket]);

  return (
    <Container>
      {socket ? (
        <Static number={props.number} socket={socket} />
      ) : (
        <h1>not connected</h1>
      )}
    </Container>
  );
};

export default PlayingRoom;
