import styled from "styled-components";

import background from "../../assets/skin-assets/background_1.png";

export const Container = styled.div`




.warn{
      border:2px solid cyan;
      border-radius: 13px;
height:100px;
    }
  width: 100vw;
  max-height: 100vh;
  height: 100vh;
  background-image: url(${background});
  background-size: 100% 100%;
  overflow: hidden;
  object-fit: cover;

  .window {
    width: 100%;
    max-height: 100vh;
    display: grid;
    grid-template-columns: 15% 1fr 15%;
    overflow: hidden;
  }

  .left {
    max-height: 100vh;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

  }
  .center {
    display: grid;
    grid-template-rows: 550px 1fr;
  }

  .right {
    padding-top: 70vh;
    padding-bottom: 30px;
  }
  .fill {
    width: 60vw;
    max-height: 100%;
    margin-left: 50%;
    transform: translateX(-50%);
    padding: 0;
  }
  .playing-field {
    height: 550px;

    width: auto;
    position: relative;
  }
  .take10 {
    position: absolute;
    width: 80px;
    left: 290px;
    top: 110px;

    transform: rotate(30deg);
  }

  .take10-card{
    width: 90%;
    


  }
  }


  .played-cards {
    width: 6vw;
    height: auto;
    /* border: 2px solid red; */
  }

  .played-cards :nth-child(1) {
    z-index: 5;
  }
  .played-cards :nth-child(2) {
    transform: translateX(-10px) rotate(-10deg);
    z-index: 4;
  }

  .played-cards :nth-child(3) {
    transform: translateX(-20px) rotate(-20deg);
    z-index: 3;
  }

  .played-cards :nth-child(4) {
    z-index: 2;
  }
  .played-cards :nth-child(5) {
    transform: translateX(-10px) rotate(-10deg);
    z-index: 1;
  }

  .played-cards :nth-child(6) {
    transform: translateX(-20px) rotate(-20deg);
    z-index: 0;
  }

  .played {
    max-width: 90px !important;
    position: absolute;
    top: 280px;
    left: 47%;
    max-width: 90%;
    width: 6vw;
  }

  .top {
    margin: 15px 0;
  }

  .own-cards {
    height: 100%;
    display: flex;
    justify-content: space-around;
  }
  .singele-siude {
    height: 24vh;
    position: relative;
    cursor: pointer;
    width: 100%;
    margin-top: 30px;
    .none{
      display:none!important;
    }
    @keyframes shaker {
	0% {transform: translate(190px, 0px); }
	20% {transform: translate(190px, 15px); }
  40%{transform: translate(190px, -15px);}
  60% {transform: translate(190px, 15px); }
  80% {transform: translate(190px, -15px); }
  100% {transform: translate(190px, 0px); }
 
}

    .warn{
      border:2px solid cyan;
      border-radius: 13px;
  animation-name: shaker;
animation-duration: 1s;
/* transform-origin:50% 50%; */
animation-timing-function: linear;
    }
    .henok:hover {
      transform: translate(0px, -10px) !important;
    }
  }
  .my-card {
    height: 100%;
    position: absolute;
  }

  .bottom {
    display: grid;
    height: 32vh;
    grid-template-columns: 1fr;
    grid-gap: 50px;
    padding: 15px 20px;
    /* position: absolute; */
  }

  .wish {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 15px;
    width: 95%;
    padding: 30px;
    position: relative;
  }
  .wish-siude {
    width: 100%;
    height: auto;
  }
  .nine {
    position: absolute;
    top: 47%;
    left: 50%;
    width: 50px;
    height: auto;
    transform: translate(-50%, -50%);
  }

  .wish-siude:hover {
    transform: scale(1.1);
  }

  .nine:hover {
    transform: scale(1) translate(-50%, -50%);
  }

  .singele-siude :nth-child(13) {
    transform: translateX(-390px);
    z-index: 7;
  }
  .singele-siude :nth-child(12) {
    transform: translateX(-360px);
    z-index: 8;
  }

  .singele-siude :nth-child(11) {
    transform: translateX(-330px);
    z-index: 9;
  }

  .singele-siude :nth-child(10) {
    transform: translateX(-300px);
    z-index: 10;
  }
  .singele-siude :nth-child(9) {
    transform: translateX(-270px);
    z-index: 11;
  }
  .singele-siude :nth-child(8) {
    transform: translateX(-240px);
    z-index: 12;
  }

  .singele-siude :nth-child(7) {
    transform: translateX(-210px);
    z-index: 13;
  }

  .singele-siude :nth-child(6) {
    transform: translateX(-180px);
    z-index: 14;
  }
  .singele-siude :nth-child(5) {
    transform: translateX(-150px);
    z-index: 15;
  }

  .singele-siude :nth-child(4) {
    transform: translateX(-120px);
    z-index: 16;
  }

  .singele-siude :nth-child(3) {
    transform: translateX(-90px);
    z-index: 17;
  }
  .singele-siude :nth-child(2) {
    transform: translateX(-60px);
    z-index: 18;
  }

  .singele-siude :nth-child(1) {
    transform: translateX(-30px);
    z-index: 19;
  }


  .singele-siude :nth-child(1).first-card{

    transform: translateX(190px);
    z-index: 19;

  }
  .singele-siude :nth-child(1).first-card:hover{
    right:125px;
  }

  .singele-siude :nth-child(1).warn.first-card:hover{
    right:340px;
  }


  .singele-siude :nth-child(1) {
    transform: translateX(-30px);
    z-index: 19;
    /* border: 2px solid red; */
  }
  .singele-siude :nth-child(25) {
    transform: translateX(330px);
    z-index: 41;
  }
  .singele-siude :nth-child(24) {
    transform: translateX(300px);
    z-index: 40;
  }
  .singele-siude :nth-child(23) {
    transform: translateX(270px);
    z-index: 39;
  }
  .singele-siude :nth-child(22) {
    transform: translateX(240px);
    z-index: 38;
  }
  .singele-siude :nth-child(21) {
    transform: translateX(210px);
    z-index: 37;
  }
  .singele-siude :nth-child(20) {
    transform: translateX(180px);
    z-index: 36;
  }
  .singele-siude :nth-child(19) {
    transform: translateX(150px);
    z-index: 35;
  }
  .singele-siude :nth-child(18) {
    transform: translateX(120px);
    z-index: 34;
  }

  .singele-siude :nth-child(17) {
    transform: translateX(90px);
    z-index: 33;
  }

  .singele-siude :nth-child(16) {
    transform: translateX(60px);
    z-index: 32;
  }
  .singele-siude :nth-child(15) {
    transform: translateX(30px);
    z-index: 31;
  }

  .singele-siude :nth-child(14) {
    transform: translateX(0px);

    z-index: 30;
  }

  .singele-siude img .wish img:hover {
    transform: scale(1.1);
  }
  .draw {
    width: 75px !important;
    position: absolute;
    top: 123px;
    right: 279px;
  }

  .suits {
    width: 62px !important;
    position: absolute;
    top: 410px;
    left: 272px;
  }

  .draw:hover {
    transform: scale(1.1);
  }

  .knock {
    width: 100%;
    height: 100%;
    position: relative;
bottom:20px;
right:30px;
    img {
      width: 7vw;
    }
    img:hover {
      transform: scale(1.1);
    }
    .knock-button {
      width: 8.5vw !important;
      position: absolute;
      bottom: 0;
      left: 30%;
    }
    .skip {
      position: absolute;
      top: 0px;
      left: 7.2vw;
    }

    .force-10 {
      position: absolute;
      top: 50px;
      left: -30px;
    }
  }

  @media screen and (max-height: 780px) {
    .center {
      grid-template-rows: 500px 1fr;
    }
    .playing-field {
      height: 500px;
    }

    .suits {
      width: 56px !important;
      top: 372.5px;
      left: 287px;
    }

    .played {
      max-width: 80px !important;
      position: absolute;
      top: 255px;
      left: 47%;
      max-width: 90%;
      width: 6vw;
    }

    .draw {
      width: 70px !important;
      position: absolute;
      top: 110px;
      right: 292px;
    }

    .wish {
      height: 15vw;
      grid-template-columns: 1fr 1fr;
    }

    .nine {
      width: 50px;
      top: 45%;
    }
    .take10 {
      position: absolute;
      width: 70px;
      left: 305px;
      top: 100px;

      transform: rotate(30deg);
    }

    .singele-siude {
      margin-top: 0;
    }
    .right {
      padding-top: 67vh;
    }
  } 
  @media screen and (max-height: 680px) {
    .center {
      grid-template-rows: 450px 1fr;
    }
    .playing-field {
      height: 450px;
    }

    .suits {
      width: 50px !important;
      top: 335.5px;
      left: 254px;
    }

    .played {
      max-width: 70px !important;
      position: absolute;
      top: 227px;
      left: 47%;
      max-width: 90%;
      width: 6vw;
    }

    .draw {
      width: 60px !important;
      position: absolute;
      top: 100px;
      right: 257px;
    }

    .wish {
      height: 15vw;
      grid-template-columns: 1fr 1fr;
    }

    .nine {
      width: 50px;
      top: 45%;
    }
    .take10 {
      position: absolute;
      width: 70px;
      left: 305px;
      top: 100px;

      transform: rotate(30deg);
    }

    .singele-siude {
      margin-top: 0;
    }
    .right {
      padding-top: 67vh;
    }
  }
  
`;

export const Card = styled.img`
  height: 100%;
  position: absolute;

  &:hover {
    transform: translate(
      ${(props) =>
        props.index > 11 ? (props.index - 13) * 30 : props.index}px,
      -10px
    ) !important;
  }
`;

export const PlayersContainer = styled.div`
  width: 25vw;
  /* height: 2px; */
  /* min-height: 200px; */

  height: fit-content;

  padding-left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0px 30px;
  /* padding-top: 30px; */

  float: inline-end;

  .dot-red {
    width: 30px;
    height: 30px;
    border: 2px solid #d9d2c8;
    border-radius: 15px;
    background-color: red;
    margin-top: 2%;
    margin-left: 10px;
    margin-right: 10px;
  }
  .dot-green {
    width: 30px;
    height: 30px;
    border: 2px solid #d9d2c8;
    border-radius: 15px;
    background-color: green;
    margin-top: 2%;
    margin-left: 10px;
    margin-right: 10px;
  }
  .other-player {
    background-color: #65a4cd;
    border: 2px solid #d9d2c8;
    border-radius: 5px;
    width: 60%;
    height: fit-content;
    color: black;
    display: flex;
    text-align: left;
    padding: 10px;
    font-size: 11px;
    font-weight: 100 !important;
    margin-top: 30px;
  }

  & div:nth-child(${(props) => props.turn}) .dot {
    background: green;
  }
`;
