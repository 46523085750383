import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import randomCodeGenerator from "../utils/randomCodeGenerator";
import io from "socket.io-client";
import { ENDPOINT } from "../api/Api";

const Homepage = ({ setNumberOfPlayers, number }) => {
  const [roomCode, setRoomCode] = useState("");
  const [games, setGames] = useState([]);

  let socket;

  useEffect(() => {
    const connectionOptions = {
      forceNew: true,
      reconnectionAttempts: "Infinity",
      timeout: 10000,
      transports: ["websocket"],
      path: "/mysocket",
    };
    socket = io.connect(ENDPOINT, connectionOptions);

    socket.emit("getRooms", {});
    socket.on("rooms", (games) => {
      setGames(games);
      console.log("games ", games);
    });
  }, []);

  return (
    <div className="Homepage">
      <div className="homepage-menu">
        <img src={require("../assets/skin-logo.png")} width="200px" />
        <div className="homepage-form">
          {/* <div className="homepage-join">
            <input
              type="text"
              placeholder="Game Code"
              onChange={(event) => setRoomCode(event.target.value)}
              className="inputField"
            />
            <Link to={`/playing-room?roomCode=${roomCode}`}>
              <button className="game-button green">JOIN GAME</button>
            </Link>
          </div> */}
          {/* <h1>OR</h1> */}
          <div className="homepage-create">
            {/* <input
              type="number"
              value={number}
              placeholder="Number Of Players"
              onChange={(event) => setNumberOfPlayers(event.target.value)}
            /> */}
<div style={{display:'flex',marginBottom:'30px',marginLeft:'0px'}}>
  <h1>Join</h1>
  
   <select style={{margin:'0 20px',width:'fit-content',height:'fit-content',color:'#fff',backgroundColor:'transparent',marginTop:'0px',fontSize:'32px',fontWeight:'900'}}
              className="select-number"
              onChange={(e) => setNumberOfPlayers(e.target.value)}
            >
              <option>2</option>
              <option>3</option>
              <option>4</option>
              <option>5</option>
            </select>
  <h1>Players Room</h1>
</div>
           

            <Link to={`/playing-room`}>
              <button className="game-button orange" >Find Room</button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Homepage;
