import { useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Homepage from "./components/Homepage";
import PlayingRoom from "./pages/playing-room/playing-room.page";
import Login from "./pages/sign-in/sign-in.page";
import SignUp from "./pages/sign-up/sign-up.page";

const App = () => {
  const [number, setNumber] = useState(2);
  const setNumberOfPlayers = (num) => {
    setNumber(num);
  };
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route
            path="/game"
            exact
            element={
              <Homepage
                setNumberOfPlayers={setNumberOfPlayers}
                number={number}
              />
            }
          />
          <Route path="/" element={<Login />} />
          <Route path="/sign-up" element={<SignUp />} />
          <Route
            path="/playing-room"
            element={<PlayingRoom number={number} />}
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default App;
