export const playCard = (played_card, currentSuits, currentNumber,playedCardsPile, wish) => {
  /*
       return type meaning
    0 normal game                 default
    1 color orr number didint atch     
    2 played 7 push 2 cards to temp7
    4 give card to another player 10
    5 knock 
    
    */

  let temp = "";
  
  switch (played_card[0]) {
    case "C":
      return { turn: 1, punish: 0, type: 5 };
    case "D":
      return { turn: 0, punish: 1, type: 1, draw: true };
    case "A":
      temp = cardLogic(played_card, currentSuits, currentNumber,playedCardsPile);
      if (wish) {
        return {
          turn: 0,
          punish: 4,
          type: 1,
        };
      }
      if (temp.type === 1) {
        return {
          turn: 0,
          punish: 4,
          type: 1,
        };
      }
      return { turn: 0, punish: 0, type: 0 };
    case "7":
      if (wish) {
        return {
          turn: 0,
          punish: 4,
          type: 1,
        };
      }
      temp = cardLogic(played_card, currentSuits, currentNumber,playedCardsPile);
      if (temp.type === 1) {
        return {
          turn: 0,
          punish: 4,
          type: 1,
        };
      }
      return { turn: 1, punish: 0, type: 2, number: 2 };

    case "8":
      console.log("you played ", 8);
      if (wish) {
        return {
          turn: 0,
          punish: 4,
          type: 1,
        };
      }
      temp = cardLogic(played_card, currentSuits, currentNumber,playedCardsPile);
      if (temp.type === 1) {
        return {
          turn: 0,
          punish: 4,
          type: 1,
        };
      }
      return { turn: 2, punish: 0, type: 0 };

    case "9":
      console.log("you played ", 9,wish);

      if (wish) {
        return { turn: 1, punish: 0, type: 3 };
      } else {
        return {
          turn: 0,
          punish: 4,
          type: 1,
        };
      }

    case "1":
      if (wish) {
        return {
          turn: 0,
          punish: 4,
          type: 1,
        };
      }

      temp = cardLogic(played_card, currentSuits, currentNumber,playedCardsPile);
      if (temp.type === 1) {
        return {
          turn: 0,
          punish: 4,
          type: 1,
        };
      }
      return {
        turn: 0,
        punish: 0,
        type: 0,
        give10: true,
      };

    case "K":
      if (played_card === "KH") {
        if (wish) {
          return {
            turn: 0,
            punish: 4,
            type: 1,
          };
        }
        console.log("you played K of Heart");
        temp = cardLogic(played_card, currentSuits, currentNumber,playedCardsPile);
        if (temp.type === 1) {
          return {
            turn: 0,
            punish: 4,
            type: 1,
          };
        }
        return { turn: 1, punish: 0, type: 2, number: 4 };
      } else {
        if (wish) {
          return {
            turn: 0,
            punish: 4,
            type: 1,
          };
        }
        return cardLogic(played_card, currentSuits, currentNumber,playedCardsPile);
      }

    default: {
      console.log("deafult called");
      if (wish) {
        return {
          turn: 0,
          punish: 4,
          type: 1,
        };
      }
      return cardLogic(played_card, currentSuits, currentNumber,playedCardsPile);
    }
  }
};

const cardLogic = (played_card, currentSuits, currentNumber,playedCardsPile) => {
  const numberOfPlayedCard = played_card[0];
  const suitOfPlayedCard = played_card[1];

  if (
    numberOfPlayedCard === currentNumber ||
    suitOfPlayedCard.toLowerCase() === currentSuits.toLowerCase() || playedCardsPile.length === 0
  ) {
    return {
      type: 0,
      turn: 1,
      punish: 0,
    };
  } else {
    return {
      turn: 0,
      punish: 4,
      type: 1,
    };
  }
};
