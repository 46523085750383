import React, { useState } from "react";

import { GoogleLogin, GoogleLogout } from "react-google-login";

import login from "../../assets/login-button.png";
import googleLogIN from "../../assets/login-with-google.png";
import signUp from "../../assets/signup.png";
import { Container } from "./sign-in.styles";

import { Link } from "react-router-dom";

import axios from "axios";

import { useNavigate } from "react-router-dom";

import { toast, ToastContainer } from "react-toastify";
import { ENDPOINT } from "../../api/Api";

export default function Login({ history }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [wrong, setWrong] = useState(false);
  let navigate = useNavigate();
  const onSignIn = (e) => {
    e.preventDefault();

    handleLogin();
  };
  const handleLogin = async () => {
    // props.history.push("/news-overview");
    console.log("clicked");
    axios
      .post(`${ENDPOINT}/api/sign-in`, {
        email: email,
        password: password,
      })
      .then(function (response) {
        sessionStorage.setItem("token", response.data);
        navigate("/game");
        console.log("redired", response);
      })
      .catch(function (error) {
        console.log("redired", error);

        setWrong(true);
        toast.error("Wrong Email or Password", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  };
  const onLoginSuccess = (res) => {
    setWrong(false);
    navigate("/game");
  };

  const onFailureSucceess = (res) => {
    console.log("login failed", res);
  };

  return (
    <Container>
      <ToastContainer></ToastContainer>
      <div className="inputs">
        <input
          type="email"
          onChange={(e) => setEmail(e.target.value)}
          value={email}
          style={{ border: wrong ? "2px solid red" : "none" }}
        />
        <input
          type="password"
          onChange={(e) => setPassword(e.target.value)}
          value={password}
          style={{ border: wrong ? "2px solid red" : "none" }}
        />
      </div>
      <button className="login" onClick={onSignIn}>
        <img src={login} alt="login" className="normal-login" />
      </button>
      <div className="bottom-buttons">
        <button>
          <Link to="/sign-up">
            <img src={signUp} alt="login" className="normal-login" />
          </Link>
        </button>

        <GoogleLogin
          clientId="78115250423-3u7mm0amvt9ehoe74fboonpi4gh351na.apps.googleusercontent.com"
          render={(renderProps) => (
            <button className="google-login" onClick={renderProps.onClick}>
              <img src={googleLogIN} alt="login" />{" "}
            </button>
          )}
          style={{ border: "2px solid green!important" }}
          onSuccess={onLoginSuccess}
          onFailure={onFailureSucceess}
          cookiePolicy={"single_host_origin"}
        />
      </div>
    </Container>
  );
}
