import React, { useEffect, useRef, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useSound from "use-sound";
import force10 from "../../assets/skin-assets/10_force_selected_false_1.png";
import knock from "../../assets/skin-assets/1_knock_selected_false_1.png";
import nine from "../../assets/skin-assets/9_wish_1.png";
import shirt from "../../assets/skin-assets/Playing_field_1.png";
import skip from "../../assets/skin-assets/skip_selected_false_1.png";
import skipedTrue from "../../assets/skin-assets/skip_selected_true_1.png";
import drawSound from "../../assets/sounds/draw.mp3";
import knockSound from "../../assets/sounds/knock.mp3";
import playMeSound from "../../assets/sounds/play-card-self.mp3";
import skipSound from "../../assets/sounds/skip.mp3";
import { drawCard } from "../../utils/draw-card";
import { findList } from "../../utils/find-list";
import { playCard } from "../../utils/play-card";
import { Card, Container, PlayersContainer } from "./static.styles";

const Static = ({ number, socket }) => {
  const navigate = useNavigate();
  let [drawCardPile, setDrawCardPile] = useState([]);

  const [room, setRoom] = useState("");
  const [roomFull, setRoomFull] = useState(false);
  const [warning, setWarning] = useState(false);
  const [take10, setTake10] = useState({
    card: "",
    playedBy: -1,
    give10: false,
  });

  const [take10Warn, setTake10Warn] = useState(false);

  const [canDraw, setCanDraw] = useState(true);

  const [wish, setWish] = useState("");

  const [startGame, setStartGame] = useState(false);

  //initialize game state
  const [gameOver, setGameOver] = useState(false);
  const [winner, setWinner] = useState("");
  const [turn, setTurn] = useState(0);

  // setPlayers(...initializePlayer);

  const [players, setPlayers] = useState([]);
  const [currentNumberOfPlayers, setCurrentNumberOfPlayers] = useState(0);
  const [knocked, setKnocked] = useState(false);

  const [skiped, setSkiped] = useState(false);

  const knockedRef = useRef(knocked);

  const [playKnockSound] = useSound(knockSound);

  const [playDrawSound] = useSound(drawSound);
  const [iPlay] = useSound(playMeSound);

  const [playSkip] = useSound(skipSound);

  const [endGame, setEndGame] = useState(false);

  const [list, setList] = useState({});

  const [me, setMe] = useState(0);

  const [temp7, setTemp7] = useState([]);

  const [currentSuits, setCurrentSuits] = useState("");
  const [currentNumber, setCurrentNumber] = useState("");
  const [playedCardsPile, setPlayedCardsPile] = useState([]);

  const [drawed, setDrawed] = useState(false);

  const delaySound = (sound, i) => {
    setTimeout(() => sound(), i * 300);
  };

  const playSound = (sound, number) => {
    for (let i = 0; i < number; i++) {
      delaySound(sound, i);
    }
  };

  useEffect(() => {
    socket.emit("join", { room: room, number: number, round: 3 }, (error) => {
      if (error) setRoomFull(true);
    });

    socket.on("joinResponse", function (message) {
      setRoom(message.room);
      setMe(message.userId);
    });
  }, []);

  const initGameState = async (rule) => {
    setStartGame(true);

    socket.emit("initGameState", {
      gameOver: false,
      turn: 0,
      players: players,
      currentSuits: currentSuits,
      currentNumber: currentNumber,
      playedCardsPile: playedCardsPile,
      drawCardPile: [...drawCardPile],
      room: room,
      currentColor: currentSuits,
    });
  };

  useEffect(() => {
    socket.on(
      "initGameState",
      ({
        gameOver,
        turn,
        players,
        currentSuits,
        currentNumber,
        playedCardsPile,
        drawCardPile,
      }) => {
        setGameOver(gameOver);
        setTurn(turn);
        setPlayers(players);
        setCurrentSuits(currentSuits);
        setCurrentNumber(currentNumber);
        playedCardsPile && setPlayedCardsPile(playedCardsPile);
        setDrawCardPile(drawCardPile);
      }
    );

    socket.on(
      "updateGameState",
      ({
        gameOver,
        turn,
        players,
        currentSuits,
        currentNumber,
        playedCardsPile,
        drawCardPile,
        alertMessage,
        showAlert,
        winner,
        temp7,
        endGame,
        take10,
        list,
      }) => {
        setGameOver(gameOver);
        setTurn(turn);
        setPlayers(players);
        setCurrentSuits(currentSuits);
        setCurrentNumber(currentNumber);
        setPlayedCardsPile(playedCardsPile);
        setDrawCardPile(drawCardPile);

        showAlert &&
          toast.error(alertMessage, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        setWinner(winner);
        temp7 && setTemp7([...temp7]);
        setEndGame(endGame);
        take10 && setTake10(take10);

        list && setList(list);
      }
    );

    socket.on(
      "roomData",
      ({ startGame, rule, currentNumberOfPlayers, players, drawCardPile }) => {
        setPlayers(players);
        setDrawCardPile(drawCardPile);
        setCurrentNumberOfPlayers(currentNumberOfPlayers);
        if (startGame) {
          initGameState(rule);
        }
      }
    );

    socket.on("gamecanceled", ({ player }) => {
      console.log(player, "here is the let on");
      toast.warning(`${player} Left the game updating game state.... `, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      setTimeout(() => {
        navigate("/game");
      }, 3000);
    });

    socket.on("currentUserData", ({ name }) => {});
  }, []);

  const checkGameOver = (arr) => {
    return arr.length === 0;
  };

  const checkWinner = (arr, player) => {
    // if( arr.length === 2){}
    setWinner(arr.length === 0 ? player : winner);
    return arr.length === 0 ? player : winner;
  };

  const handlePlay = async (a) => {
    if (
      turn === me &&
      a !== players[me].cards[0] &&
      playedCardsPile.length === 0
    ) {
      setWarning(true);
      setTimeout(() => setWarning(false), 1000);
      toast.warning("you can only play the provided card", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    if (take10.give10 && turn === me) {
      if (playedCardsPile[0][0] === "1" && a[0] !== "D") {
        setTake10Warn(true);
        setTimeout(() => setTake10Warn(false), 1000);
        toast.warning(`drag and drop a card to "Take 10" area`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return;
      }
    }

    const removeIndex = players[me].cards.indexOf(a);

    if (playedCardsPile.length > 3) {
      drawCardPile.push(...playedCardsPile.splice(-1, 1));
    }

    // check players turn and if not punish with 4 cards
    let temp = [];

    if (turn === me) {
      // remove played card from player and add to played card pile

      let stateToUpdate = {
        gameOver: checkGameOver(players[me].cards),
        winner: checkWinner(players[me].cards, me + 1),
        turn: players.length - 1 === me ? 0 : me + 1,
        playedCardsPile: [...playedCardsPile],
        players: players,
        currentSuits: currentSuits,
        currentNumber: currentNumber,
        drawCardPile: drawCardPile,
        room: room,
        temp7: temp7,
        endGame: endGame,
        knocked: knocked,
        take10: take10,
        list: list,
      };
      let val;

      val = await playCard(
        a,
        currentSuits,
        currentNumber,
        playedCardsPile,
        wish
      );

      if (val.type === 2) {
        stateToUpdate.temp7 = [...temp7, ...drawCard(drawCardPile, val.number)];
        setTemp7([...temp7, ...drawCard(drawCardPile, val.number)]);
      } else {
        if (gameOver && take10.card !== "" && playedCardsPile[0][0] !== "1") {
          setEndGame(true);
          stateToUpdate.endGame = true;
        }
        playSound(playDrawSound, temp7.length);
        temp7.length > 0 && setDrawed(true);
        temp7.length > 0 && setCanDraw(true);
        players[me].cards.push(...temp7.splice(0));
      }

      if (val.draw) {
        setDrawed(true);
        setCanDraw(false);
      } else {
        setDrawed(false);
        setCanDraw(true);
      }

      if (val.type === 1) {
        if (!val.draw) {
          // setShowAlert(true);

          stateToUpdate.alertMessage =
            a[0] === "9"
              ? `MISTAKE: player ${
                  me + 1
                } played 9 before wishing , punished with 4 Cards`
              : wish
              ? `MISTAKE: player ${me + 1}
          wished but didn't play 9 , punished with 4 Cards`
              : `MISTAKE: player ${
                  me + 1
                }'s played card didn't match suits or number , punished with 4 Cards`;
          stateToUpdate.showAlert = true;
        }

        stateToUpdate.turn = me;

        const drawedCard = drawCard(drawCardPile, val.punish);

        stateToUpdate.playedCardsPile = [...playedCardsPile];

        stateToUpdate.players[stateToUpdate.turn].cards = [
          ...players[stateToUpdate.turn].cards,
          ...drawedCard,
        ];
        setDrawed(true);
      } else {
        if (val.type === 5) {
          stateToUpdate.take10 = { card: a, give10: false, playedBy: me };
          stateToUpdate.playedCardsPile = [...playedCardsPile];
        } else {
          stateToUpdate.playedCardsPile = [a, ...playedCardsPile];
          stateToUpdate.currentNumber = a[0];
          stateToUpdate.currentSuits = a[1];
        }

        stateToUpdate.turn =
          players.length - 1 < me + val.turn
            ? me + val.turn - players.length
            : me + val.turn;
        const drawedCard = drawCard(drawCardPile, val.punish);

        if (val.type === 3 && wish) {
          stateToUpdate.currentSuits = wish;
          if (gameOver) {
            if (playedCardsPile[0][0] !== "1") {
              setEndGame(true);
              // stateToUpdate.endGame(true);
              stateToUpdate.endGame = true;
            }
          }
        }

        stateToUpdate.players[stateToUpdate.turn].cards = [
          ...players[stateToUpdate.turn].cards,
          ...drawedCard,
        ];
        temp = [players[me].cards.splice(removeIndex, 1), ...playedCardsPile];
      }

      stateToUpdate.gameOver = checkGameOver(players[me].cards);

      stateToUpdate.winner = checkWinner(players[me].cards, me + 1);
      if (a[0] !== "D") playSound(iPlay, 1);

      let temp1 = [...players];
      temp1.splice(take10.playedBy, 1);

      stateToUpdate.list = findList(players, take10);

      if (players[me].cards.length === 1) {
        stateToUpdate.turn = me;
        socket.emit("updateGameState", stateToUpdate);

        let time = 0;

        const checkKnock = setInterval(() => {
          if (knockedRef.current) {
            stateToUpdate.turn =
              players.length - 1 < me + val.turn
                ? me + val.turn - players.length
                : me + val.turn;
            knockedRef.current = false;
            endTimer();
            time = 5000;
            socket.emit("updateGameState", stateToUpdate);
          } else if (time === 3000) {
            const drawedCard = drawCard(drawCardPile, 4);
            players[me].cards = [...players[me].cards, ...drawedCard];
            socket.emit("updateGameState", {
              gameOver: checkGameOver(players[me].cards),
              winner: checkWinner(players[me].cards, me + 1),
              turn:
                players.length - 1 < me + val.turn
                  ? me + val.turn - players.length
                  : me + val.turn,
              playedCardsPile: playedCardsPile,
              players: players,
              currentSuits: currentSuits,
              currentNumber: currentNumber,
              drawCardPile: drawCardPile,
              room: room,
              showAlert: true,
              temp7: temp7,
              alertMessage: `MISTAKE: player ${
                me + 1
              } forgot to knock, punished with 4 cards `,
            });
            endTimer();
            time = 5000;
          } else {
            time += 500;
          }
        }, 500);

        const endTimer = () => {
          clearInterval(checkKnock);
          time = 5000;
        };
      } else {
        if (take10.playedBy !== -1 && take10.playedBy !== me) {
          let temp1 = [...players];
          temp1.splice(take10.playedBy, 1);

          let list = findList(players, take10);

          stateToUpdate.players[
            players.findIndex((player) => player.name === list.player)
          ].cards.push(take10.card);

          stateToUpdate.take10 = {
            card: "",
            playedBy: -1,
            give10: false,
          };
        }
        if (val.give10) {
          setTake10({ give10: true, playedBy: me, card: "" });
          stateToUpdate.take10 = { give10: true, playedBy: me, card: "" };
        }
        socket.emit("updateGameState", stateToUpdate);
      }
    } else if (a[1] == currentSuits && a[0] == currentNumber) {
      let stateToUpdate = {
        gameOver: checkGameOver(players[me].cards),
        winner: checkWinner(players[me].cards, me + 1),
        turn: players.length - 1 == me ? 0 : me + 1,
        // turn: turn,
        playedCardsPile: [...playedCardsPile],
        players: players,
        currentSuits: currentSuits,
        currentNumber: currentNumber,
        drawCardPile: drawCardPile,
        room: room,
      };
      const val = await playCard(
        a,
        currentSuits,
        currentNumber,
        playedCardsPile,
        wish
      );

      if (val.type === 1) {
        stateToUpdate.turn = me;

        const drawedCard = drawCard(drawCardPile, val.punish);

        stateToUpdate.playedCardsPile = [...playedCardsPile];

        stateToUpdate.players[stateToUpdate.turn].cards = [
          ...players[stateToUpdate.turn].cards,
          ...drawedCard,
        ];
      } else {
        stateToUpdate.currentNumber = a[0];
        stateToUpdate.currentSuits = a[1];
        stateToUpdate.playedCardsPile = [a, ...playedCardsPile];
        if (val.type === 2) {
          players[turn].cards.push(...temp7.splice(0));
        }
        stateToUpdate.turn =
          players.length - 1 < me + val.turn
            ? me + val.turn - players.length
            : me + val.turn;
        const drawedCard = drawCard(drawCardPile, val.punish);

        if (val.type === 3 && wish) {
          stateToUpdate.currentSuits = wish;
        }
        stateToUpdate.players[stateToUpdate.turn].cards = [
          ...players[stateToUpdate.turn].cards,
          ...drawedCard,
        ];
        temp = [players[me].cards.splice(removeIndex, 1), ...playedCardsPile];
      }

      playSound(iPlay, 1);

      stateToUpdate.gameOver = checkGameOver(players[me].cards);
      stateToUpdate.winner = checkWinner(players[me].cards, me + 1);
      if (val.give10) {
        setTake10({ give10: true, playedBy: me, card: "" });
        stateToUpdate.take10 = { give10: true, playedBy: me, card: "" };
      }
      socket.emit("updateGameState", stateToUpdate);
    } else {
      const drawedCard = drawCard(drawCardPile, 4);
      players[me].cards = [...players[me].cards, ...drawedCard];

      socket.emit("updateGameState", {
        gameOver: checkGameOver(players[me].cards),
        winner: checkWinner(players[me].cards, me + 1),
        turn: turn,
        playedCardsPile: [...playedCardsPile],
        players: players,
        currentSuits: currentSuits,
        currentNumber: currentNumber,
        drawCardPile: drawCardPile,
        room: room,
        showAlert: true,
        alertMessage: `MISTAKE: player ${me + 1} playes in Player ${
          turn + 1
        }'s turn, punished with 4 Cards`,
      });
    }
    // playCard(a, currentSuits, currentNumber));

    setWish("");
  };

  const handleWish = (e) => {
    if (wish === e.target.name) {
      setWish("");
    } else setWish(e.target.name);
  };

  const handleDraw = () => {
    if (gameOver && playedCardsPile[0][0] !== "1") {
      setEndGame(true);
      socket.emit("updateGameState", {
        gameOver: checkGameOver(players[me].cards),
        winner: checkWinner(players[me].cards, me + 1),
        turn: turn,
        playedCardsPile: [...playedCardsPile],
        players: players,
        currentSuits: currentSuits,
        currentNumber: currentNumber,
        drawCardPile: drawCardPile,
        room: room,
        endGame: true,
        temp7: temp7,
      });
    } else if (temp7.length > 0 && turn === me) {
      playSound(playDrawSound, temp7.length);

      players[me].cards.push(...temp7.splice(0));

      setDrawed(true);
      setCanDraw(true);
      socket.emit("updateGameState", {
        gameOver: checkGameOver(players[me].cards),
        winner: checkWinner(players[me].cards, me + 1),
        turn: turn,
        playedCardsPile: [...playedCardsPile],
        players: players,
        currentSuits: currentSuits,
        currentNumber: currentNumber,
        drawCardPile: drawCardPile,
        room: room,
      });
    } else if (drawed && !canDraw && players[me].cards.length > 0) {
      const drawedCard = drawCard(drawCardPile, 4);

      players[me].cards = [...players[me].cards, ...drawedCard];
      socket.emit("updateGameState", {
        gameOver: checkGameOver(players[me].cards),
        winner: checkWinner(players[me].cards, me + 1),
        turn: turn,
        playedCardsPile: [...playedCardsPile],
        players: players,
        currentSuits: currentSuits,
        currentNumber: currentNumber,
        drawCardPile: drawCardPile,
        room: room,
        showAlert: true,
        alertMessage: `MISTAKE: player ${me + 1}  ${
          turn === me
            ? "tried to Draw two cards "
            : `played in Player ${turn + 1}'s turn`
        }and punished with 4 cards `,
      });
    } else {
      setDrawed(true);
      if (playedCardsPile.length > 0 && turn === me)
        playSound(playDrawSound, 1);
      handlePlay("D7");
    }
  };

  const handleSkip = () => {
    let stateToUpdate = {
      gameOver: checkGameOver(players[me].cards),
      winner: checkWinner(players[me].cards, me + 1),
      turn: players.length - 1 === me ? 0 : me + 1,
      playedCardsPile: playedCardsPile,
      players: players,
      currentSuits: currentSuits,
      currentNumber: currentNumber,
      drawCardPile: drawCardPile,
      room: room,
      temp7: [],
    };
    if (drawed) {
      socket.emit("updateGameState", stateToUpdate);
      setDrawed(false);
      setCanDraw(true);
      setSkiped(true);
      playSound(playSkip, 1);

      setTimeout(() => setSkiped(false), 500);
    } else {
      const drawedCard = drawCard(drawCardPile, 4);

      players[me].cards = [...players[me].cards, ...drawedCard];
      socket.emit("updateGameState", {
        gameOver: checkGameOver(players[me].cards),
        winner: checkWinner(players[me].cards, me + 1),
        turn: turn,
        playedCardsPile: [...playedCardsPile],
        players: players,
        currentSuits: currentSuits,
        currentNumber: currentNumber,
        drawCardPile: drawCardPile,
        temp7: [],
        room: room,
        showAlert: true,
        alertMessage: `MISTAKE: player ${
          me + 1
        } tried to skip before drawing a card and punished with 4 cards‚`,
      });
    }
  };

  const handleKnock = () => {
    if (turn === me && players[me].cards.length === 1) {
      setKnocked(true);
      knockedRef.current = true;
      playKnockSound();
    } else {
      let myCards = players[me].cards;
      const drawedCard = drawCard(drawCardPile, 4);
      players[me].cards = [...players[me].cards, ...drawedCard];
      socket.emit("updateGameState", {
        gameOver: checkGameOver(players[me].cards),
        winner: checkWinner(players[me].cards, me + 1),
        turn: turn,
        playedCardsPile: [...playedCardsPile],
        players: players,
        currentSuits: currentSuits,
        currentNumber: currentNumber,
        drawCardPile: drawCardPile,
        room: room,
        showAlert: true,
        alertMessage:
          turn !== me
            ? `MISTAKE: player ${me + 1} playes in Player ${
                turn + 1
              }'s turn, punished with 4 Cards`
            : `MISTAKE: player ${me + 1}, knocked while having ${
                myCards.length
              } cards punished with 4 Cards`,
      });
    }
  };

  const handleforce10 = () => {
    let stateToUpdate = {
      gameOver: checkGameOver(players[me].cards),
      winner: checkWinner(players[me].cards, me + 1),
      turn: me,
      playedCardsPile: playedCardsPile,
      players: players,
      currentSuits: currentSuits,
      currentNumber: currentNumber,
      drawCardPile: drawCardPile,
      room: room,
      temp7: temp7,
      endGame: endGame,
      knocked: knocked,
      list: list,
      take10: take10,
    };

    if (take10.playedBy === -1) {
      return;
    }

    if (turn === me && take10.playedBy !== -1) {
      let temp1 = [...players];
      temp1.splice(take10.playedBy, 1);

      let list = findList(players, take10);

      stateToUpdate.players[
        players.findIndex((player) => player.name === list.player)
      ].cards.push(take10.card);

      stateToUpdate.take10 = {
        card: "",
        playedBy: -1,
        give10: false,
      };
      setTake10({
        card: "",
        playedBy: -1,
        give10: false,
      });
      console.log(take10, "also here we have take10");
      socket.emit("updateGameState", stateToUpdate);
    } else {
      const drawedCard = drawCard(drawCardPile, 4);

      players[me].cards = [...players[me].cards, ...drawedCard];

      socket.emit("updateGameState", {
        gameOver: checkGameOver(players[me].cards),
        winner: checkWinner(players[me].cards, me + 1),
        turn: turn,
        playedCardsPile: [...playedCardsPile],
        players: players,
        currentSuits: currentSuits,
        currentNumber: currentNumber,
        drawCardPile: drawCardPile,
        room: room,
        showAlert: true,
        alertMessage:
          take10.playedBy !== -1
            ? `MISTAKE: player ${
                me + 1
              }tried to force 10, punished with 4 Cards`
            : `MISTAKE: player ${me + 1} playes in Player ${
                turn + 1
              }'s turn, punished with 4 Cards`,
      });
    }

    // console.log(players.findIndex((player) => player.name === list.player));
  };

  const draw10 = () => {
    let stateToUpdate = {
      gameOver: checkGameOver(players[me].cards),
      winner: checkWinner(players[me].cards, me + 1),
      turn: me,
      playedCardsPile: playedCardsPile,
      players: players,
      currentSuits: currentSuits,
      currentNumber: currentNumber,
      drawCardPile: drawCardPile,
      room: room,
      temp7: temp7,
      endGame: endGame,
      knocked: knocked,
      list: list,
      take10: take10,
    };

    if (turn === me && take10.playedBy !== -1) {
      let temp1 = [...players];
      temp1.splice(take10.playedBy, 1);
      stateToUpdate.players[me].cards.push(take10.card);

      stateToUpdate.take10 = {
        card: "",
        playedBy: -1,
        give10: false,
      };
      setTake10({
        card: "",
        playedBy: -1,
        give10: false,
      });

      socket.emit("updateGameState", stateToUpdate);
    } else {
      const drawedCard = drawCard(drawCardPile, 4);
      players[me].cards = [...players[me].cards, ...drawedCard];
      socket.emit("updateGameState", {
        gameOver: checkGameOver(players[me].cards),
        winner: checkWinner(players[me].cards, me + 1),
        turn: turn,
        playedCardsPile: [...playedCardsPile],
        players: players,
        currentSuits: currentSuits,
        currentNumber: currentNumber,
        drawCardPile: drawCardPile,
        room: room,
        showAlert: true,
        alertMessage:
          take10.playedBy !== -1
            ? `MISTAKE: player ${
                me + 1
              }tried to force 10, punished with 4 Cards`
            : `MISTAKE: player ${me + 1} playes in Player ${
                turn + 1
              }'s turn, punished with 4 Cards`,
      });
    }
  };

  const onDragStart = (e) => {
    console.log("drag started", e);

    document.getElementsByClassName("cards-to-drag")[
      parseInt(e.draggableId.slice(-1))
    ].style.width = "80px";
    document.getElementsByClassName("cards-to-drag")[
      parseInt(e.draggableId.slice(-1))
    ].style.height = "auto";
  };

  const onDragEnd = (result) => {
    let { destination, source } = result;

    if (currentNumber === "1" && turn === me && take10.playedBy === me) {
      if (destination.droppableId == source.droppableId) return;

      let temp = players[me].cards.splice(source.index, 1);

      setTake10({
        card: temp,
        playedBy: me,
        give10: false,
      });

      let stateToUpdate = {
        gameOver: checkGameOver(players[me].cards),
        winner: checkWinner(players[me].cards, me + 1),
        turn: players.length - 1 === me ? 0 : me + 1,
        playedCardsPile: [...playedCardsPile],
        players: players,
        currentSuits: currentSuits,
        currentNumber: currentNumber,
        drawCardPile: drawCardPile,
        room: room,
        temp7: temp7,
        endGame: endGame,
        knocked: knocked,
        take10: take10,
        list: list,
      };

      stateToUpdate.take10 = { card: temp[0], give10: false, playedBy: me };
      stateToUpdate.playedCardsPile = [...playedCardsPile];

      playSound(iPlay, 1);

      stateToUpdate.gameOver = checkGameOver(players[me].cards);
      stateToUpdate.winner = checkWinner(players[me].cards, me + 1);
      if (players[me].cards.length === 1) {
        stateToUpdate.turn = me;
        socket.emit("updateGameState", stateToUpdate);

        let time = 0;

        const checkKnock = setInterval(() => {
          if (knockedRef.current) {
            stateToUpdate.turn =
              players.length - 1 === me
                ? 0
                : me + 1((knockedRef.current = false));
            endTimer();
            time = 5000;
            socket.emit("updateGameState", stateToUpdate);
          } else if (time === 3000) {
            const drawedCard = drawCard(drawCardPile, 4);
            players[me].cards = [...players[me].cards, ...drawedCard];
            socket.emit("updateGameState", {
              gameOver: checkGameOver(players[me].cards),
              winner: checkWinner(players[me].cards, me + 1),
              turn: (stateToUpdate.turn =
                players.length - 1 === me ? 0 : me + 1),
              playedCardsPile: playedCardsPile,
              players: players,
              currentSuits: currentSuits,
              currentNumber: currentNumber,
              drawCardPile: drawCardPile,
              room: room,
              showAlert: true,
              temp7: temp7,
              alertMessage: `MISTAKE: player ${
                me + 1
              } forgot to knock, punished with 4 cards `,
            });
            endTimer();
            time = 5000;
          } else {
            time += 500;
          }
        }, 500);

        const endTimer = () => {
          clearInterval(checkKnock);
          time = 5000;
        };
      } else {
        socket.emit("updateGameState", stateToUpdate);
      }
    }
  };

  return (
    <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
      <Container>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        {roomFull ? (
          <h1 style={{ margiTop: "40vh" }}>This Room Is Full</h1>
        ) : !startGame ? (
          <div style={{ marginTop: "40vh" }}>
            <h1 style={{ margiTop: "40vh" }}>Waiting For Players </h1>

            <h2>
              waiting Players:{currentNumberOfPlayers}/{number}
            </h2>
          </div>
        ) : endGame ? (
          <h1 style={{ marginTop: "40vh" }}>
            {winner === me + 1 ? "You" : `Player ${winner}`} won the game
          </h1>
        ) : (
          <div className="window">
            <div className="left">
              <PlayersContainer turn={turn + 1}>
                {players &&
                  players.map((player, index) => (
                    <div className="other-player">
                      <div className="dot-red dot"></div>
                      <div className="desc">
                        <h3>
                          {" "}
                          {index === me ? "You" : `Player: ${index + 1}`}{" "}
                        </h3>
                        <h3>Cards: {player.cards.length}</h3>
                      </div>
                    </div>
                  ))}
              </PlayersContainer>
              <div className="wish">
                <img
                  src={require(`../../assets/skin-assets/9_wish_diamonds_selected_${
                    wish === "D"
                  }_1.png`)}
                  alt="my card"
                  name="D"
                  className="wish-siude"
                  onClick={handleWish}
                />
                <img
                  src={require(`../../assets/skin-assets/9_wish_clubs_selected_${
                    wish === "C"
                  }_1.png`)}
                  alt="my card"
                  name="C"
                  className="wish-siude"
                  onClick={handleWish}
                />
                <img
                  src={require(`../../assets/skin-assets/9_wish_spades_selected_${
                    wish === "S"
                  }_1.png`)}
                  alt="my card"
                  name="S"
                  className="wish-siude"
                  onClick={handleWish}
                />
                <img
                  src={require(`../../assets/skin-assets/9_wish_hearts_selected_${
                    wish === "H"
                  }_1.png`)}
                  alt="my card"
                  name="H"
                  className="wish-siude"
                  onClick={handleWish}
                />
                <img src={nine} alt="my card" className="wish-siude nine" />
              </div>
            </div>

            <div className="center">
              <div className="fill">
                <img
                  src={shirt}
                  alt="playing field"
                  className="playing-field"
                />

                <div className={`take10 ${take10Warn && "warn"}`}>
                  <Droppable droppableId="take10">
                    {(provided) => {
                      return (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          {take10.card !== "" && take10.playedBy === me ? (
                            <img
                              src={require(`../../assets/batel-chan-cards/${take10.card}.png`)}
                              alt="take 10"
                              className="take10-card"
                              onClick={draw10}
                            />
                          ) : take10.card.length ? (
                            <img
                              src={require(`../../assets/batel-chan-cards/06_Card_back.png`)}
                              alt="take 10"
                              className="take10-card"
                              onClick={draw10}
                            />
                          ) : null}
                        </div>
                      );
                    }}
                  </Droppable>
                </div>

                <div className="played-cards">
                  {playedCardsPile.length > 0 && (
                    <img
                      src={require(`../../assets/batel-chan-cards/${playedCardsPile[0]}.png`)}
                      alt="my card"
                      className="played"
                    />
                  )}
                  {playedCardsPile.length > 1 && (
                    <img
                      src={require(`../../assets/batel-chan-cards/${playedCardsPile[1]}.png`)}
                      alt="my card"
                      className="played"
                    />
                  )}
                  {playedCardsPile.length > 2 && (
                    <img
                      src={require(`../../assets/batel-chan-cards/${playedCardsPile[2]}.png`)}
                      alt="my card"
                      className="played"
                    />
                  )}
                </div>

                <img
                  src={require(`../../assets/batel-chan-cards/06_Card_back.png`)}
                  alt="draw card"
                  className="draw"
                  onClick={handleDraw}
                />
                {currentSuits && (
                  <img
                    src={require(`../../assets/skin-assets/${currentSuits}.png`)}
                    alt="draw"
                    className="suits"
                  />
                )}
              </div>

              <div className="bottom">
                <div className="own-cards ">
                  <Droppable droppableId="my-cards">
                    {(provided) => {
                      return (
                        <div
                          className="singele-siude"
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          {players[me].cards
                            .sort((a, b) => {
                              if (a.charAt(1) < b.charAt(1)) {
                                return -1;
                              }
                              if (a.charAt(1) > b.charAt(1)) {
                                return 1;
                              }
                              // a must be equal to b
                              return 0;
                            })
                            .map((a, index) => (
                              <Draggable
                                draggableId={`card-${index}`}
                                index={index}
                                key={index}
                                isDragDisabled={playedCardsPile.length < 1}
                                dropDisabled={playedCardsPile.length < 1}
                              >
                                {(provided) => {
                                  return (
                                    <Card
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      src={require(`../../assets/batel-chan-cards/${a}.png`)}
                                      alt="my card"
                                      key={index}
                                      className={`my-card ${
                                        index === 13 && "henok"
                                      } cards-to-drag ${
                                        index === 0 &&
                                        playedCardsPile.length < 1 &&
                                        turn === me &&
                                        "first-card"
                                      }
                              
                                      ${warning && "warn"}`}
                                      index={
                                        index < 14 ? (index + 1) * -30 : index
                                      }
                                      onClick={() => handlePlay(a)}
                                    />
                                  );
                                }}
                              </Draggable>
                            ))}
                        </div>
                      );
                    }}
                  </Droppable>
                </div>
              </div>
            </div>

            <div className="right">
              <div className="knock">
                <img
                  src={knock}
                  alt="my card"
                  className="knock-button"
                  onClick={handleKnock}
                />
                <img
                  src={force10}
                  alt="my card"
                  className="force-10"
                  onClick={handleforce10}
                />
                <img
                  src={skiped ? skipedTrue : skip}
                  alt="my card"
                  className="skip"
                  onClick={handleSkip}
                />
              </div>
            </div>
          </div>
        )}
      </Container>
    </DragDropContext>
  );
};

export default Static;
